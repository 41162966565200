import React from "react";
import { Link } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import codeImage from "../images/wat.png";

const IndexPage: React.FC = () => (
    <Layout>
        <SEO title="Autism Success in Tech | Helping people with Asperger's/autism achieve success" />

        <section className="section">
            <div className="container content">
                <div className="hero home-hero is-light">
                    <div className="hero-body">
                        <div className="container">
                            <h1 className="title is-1">
                                Autism Success in Tech
                            </h1>
                            <p className="subtitle">
                                Helping people on the autism spectrum achieve
                                success in the tech industry
                            </p>
                            <Link
                                className="button is-link is-large"
                                to="/join/"
                            >
                                Join Us
                            </Link>{" "}
                            <Link
                                to="/about/"
                                className="button is-large is-white"
                            >
                                Learn More
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="section">
            <div className="container content">
                <div className="columns">
                    <div className="column">
                        <h2 className="title is-2">Learn to Code</h2>
                        <p>
                            If you're new to programming, we can help you get
                            started. If you're experienced, you can help other
                            people learn. All programming languages and levels
                            of experience are welcome.
                        </p>
                        <Link to="/learn/" className="button is-large">
                            Coding Resources
                        </Link>
                    </div>
                    <div className="column">
                        <img
                            class="image"
                            src={codeImage}
                            alt="Learn to code"
                        />
                    </div>
                </div>
            </div>
        </section>
        <section className="section">
            <div className="container content">
                <div className="columns">
                    <div className="column">
                        <h2 className="title is-2">Job Networking</h2>
                        <p>
                            Success depends not only on what you know but also
                            on who you know. Meeting up with other like-minded
                            people can help expand your personal social network
                            to improve your chances of finding opportunities.
                        </p>
                    </div>
                    <div className="column">
                        <h2 className="title is-2">Social Connections</h2>
                        <p>
                            One of the challenging aspects of autism is
                            difficulty with finding friends or making
                            professional connections. Our meetings provide a way
                            to be around other people with similar interests.
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <section className="section">
            <div className="container content">
                <h2 className="title is-2">Technologies</h2>
                <p>
                    We're open to all technologies and all levels of experience.
                    Beginners are welcome. Here are some example technologies
                    that people might want to work on in the group.
                </p>
                <div className="table-container">
                    <table className="table is-bordered">
                        <tbody>
                            <tr>
                                <td>Web Development</td>
                                <td>Data Science</td>
                                <td>Systems Programming</td>
                                <td>Hardware</td>
                            </tr>
                            <tr>
                                <td>Python</td>
                                <td>JavaScript</td>
                                <td>Ruby</td>
                                <td>HTML/CSS</td>
                            </tr>
                            <tr>
                                <td>Haskell</td>
                                <td>Lisp</td>
                                <td>Elixir</td>
                                <td>Erlang</td>
                            </tr>
                            <tr>
                                <td>Rust</td>
                                <td>C/C++</td>
                                <td>Java</td>
                                <td>Lisp</td>
                            </tr>
                            <tr>
                                <td>Mathematics</td>
                                <td>Scala</td>
                                <td>Databases</td>
                                <td>and much more</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    </Layout>
);

export default IndexPage;
